import React, {useState} from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button,
  ThemeProvider, Box,
  theme, CSSReset, Heading, Collapse, Image, Stack, Text, Divider, Link
} from "@chakra-ui/core";

import calculateResult from "./calculateResult";
import toHoursMinutesAndSeconds from "./toHoursMinutesAndSeconds";

import logo from "./logo.png"

function App() {
  const [salary, setSalary] = useState(0)
  const [hoursWorkedPerDay, setHoursWorkedPerDay] = useState(0)
  const [holidaysPerYear, setHolidaysPerYear] = useState(0)
  const [price, setPrice] = useState(0)
  const [result, setResult] = useState("")

  function handleClick(){
    let hours = calculateResult({salary, hoursWorkedPerDay, holidaysPerYear, price})
    setResult(toHoursMinutesAndSeconds(hours))
  }

  return (
    <div>
      <ThemeProvider theme={theme} >
        <CSSReset />
        <Stack justify="space-between" align="center" minH={"100%"} color={theme.colors.gray["700"]}>
          <Box width="lg"
               maxWidth="90vw"
               padding={4}
               m={4}
               borderWidth="1px"
               rounded="lg"
               overflow="hidden"
               bg={theme.colors.white}
          >
            <Stack isInline justifyContent={"space-between"} spacing={8}>
              <Stack>
                <Heading fontFamily={theme.fonts.heading}>Money is Work</Heading>
                <Text>Find out how long you have to work in order to earn money for the stuff that you buy</Text>
              </Stack>
              <Image height={24} src={logo} />
            </Stack>
            <Divider marginTop={4} borderWidth={2}/>
            <FormControl marginTop={4}>
              <FormLabel htmlFor="salary">How much do you earn each year after tax?</FormLabel>
              <Input type="number" id="salary" onChange={e => setSalary(e.target.value)}/>
            </FormControl>

            <FormControl marginTop={4}>
              <FormLabel htmlFor="hoursWorked">How many hours do you work per day?</FormLabel>
              <Input type="number" id="hoursWorked" onChange={e => setHoursWorkedPerDay(e.target.value)}/>
            </FormControl>

            <FormControl marginTop={4}>
              <FormLabel htmlFor="holidaysPerYear">How many days of holiday do you take in a year?</FormLabel>
              <Input type="number" id="holidaysPerYear" onChange={e => setHolidaysPerYear(e.target.value)}/>
            </FormControl>

            <FormControl marginTop={4}>
              <FormLabel htmlFor="price">How much are you going to spend?</FormLabel>
              <Input type="number" id="price" onChange={e => setPrice(e.target.value)}/>
            </FormControl>

            <Button marginTop={4} isDisabled={!(salary&&hoursWorkedPerDay&&holidaysPerYear&&price)} onClick={handleClick}>Calculate</Button>

            <Collapse isOpen={result}>
              <Box rounded="lg" bgImage={"linear-gradient(156deg, rgba(105,0,140,1) 0%, rgba(74,0,125,1) 35%, rgba(56,0,255,1) 100%)"} w="100%" mt={4} p={4} color="white">
                This will cost you <span data-testid="result">{result}</span> of solid work.
              </Box>
            </Collapse>
          </Box>
          <Stack width="100%" justify={"flex-end"} p={4} isInline>
            <Link href="https://davidcapper.dev" isExternal color={theme.colors.white}>Built by David Capper</Link>
          </Stack>
        </Stack>
      </ThemeProvider>
    </div>
  );

}

export default App;
