export default function toHoursMinutesAndSeconds(timeInSeconds){
  let [days, secondsRemainingAfterDaysExtracted] = extractDays(timeInSeconds);
  let [hours, secondsRemainingAfterHoursExtracted] = extractHours(secondsRemainingAfterDaysExtracted);
  let [minutes, seconds] = extractMinutes(secondsRemainingAfterHoursExtracted);

  return formatTime({days, hours, minutes, seconds})
}

function extractDays(timeInSeconds) {
  let days = Math.floor(timeInSeconds / 86400);
  return [days, timeInSeconds - days*86400]
}

function extractHours(timeInSeconds) {
  let hours = Math.floor(timeInSeconds / 3600);
  return [hours, timeInSeconds - hours*3600];
}

function extractMinutes(timeInSeconds) {
  let minutes = Math.floor(timeInSeconds/60);
  return [minutes, timeInSeconds - minutes*60];
}

function formatTime({days, hours, minutes, seconds}) {

  const list = []

  if (days) list.push(pluralize("day", days));
  if (hours) list.push(pluralize("hour", hours));
  if (minutes) list.push(pluralize("minute", minutes));
  if (seconds) list.push(pluralize("second", seconds));

  if (list.length === 1) return list[0]

  const everythingButTheEnd = list.slice(0,-1)
  const theEnd = list.slice(-1)

  return everythingButTheEnd.join(", ") + " and " + theEnd;
}

function pluralize(thing, amount){
  return `${amount ? amount + ` ${thing}` : ""}${amount > 1 ? "s" : ""}`;
}